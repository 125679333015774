import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { MdPowerSettingsNew } from "react-icons/md";

import { Context } from "../context/context";
import EventImage from "../assets/images/event-image.png";

const Confirmation = () => {
  const history = useHistory();
  const { dispatch } = useContext(Context);

  return (
    <div className="card border-0 mb-5 my-md-0 mb-0 mb-md-5">
      <div className="card-body">
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-8 order-2 order-md-1 text-center text-md-left my-4 my-md-0">
            <h1 className="page-title mb-0">Thank you</h1>
          </div>
          <div className="col-8 col-md-4 order-1 order-md-2">
            <img className="img-fluid" alt="event logo" src={EventImage} />
          </div>
        </div>

        <div className="row text-center text-md-left justify-content-center justify-content-md-start mt-3">
          <div className="col-12">
            <h5>Your Priority Loyalty Offer application has been received and a member of Peter Cooper Volkswagen's team will contact you with further information in accordance with your contact preferences</h5>
          </div>

        </div>

        <div className="row text-center text-md-left justify-content-center justify-content-md-start mt-5">
          <div className="col-12 col-md-6 col-lg-6">
            <div
              className="btn btn-primary"
              onClick={() => {
                dispatch({
                  type: "LOGOUT",
                  payload: {},
                });
                history.push("/");
              }}
            >
              <MdPowerSettingsNew className="mi-18" /> Logout
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
