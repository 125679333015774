import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import getSize from "viewport-size";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import { MdWarning } from "react-icons/md";

import { Context } from "../context/context";
import EventImage from "../assets/images/event-image.png";
import useTemplate from "../hooks/useTemplate";

const Priority = () => {
  let history = useHistory();
  const {
    state: {
      data: { selection, finance, partExchange, details, summary },
    },
    dispatch,
  } = useContext(Context);

  const { emailAddress, branchToEmail, branchFromEmail } = details;
  const { preferences, models } = selection;
  const { budgets, mileages, deposit } = finance;
  const { make, model, registration, mileage, transmission, fuel, colour, currentMileage, conditions, comments, contractStartDate, contractEndDate, monthlyPayment } = partExchange;
  const [isLoading, setIsLoading] = useState(false);

  const [getTemplate] = useTemplate();

  const api_key = process.env.REACT_APP_MAILGUN_API_KEY;
  const domain = "bigmarketing.co.uk";

  const mailgun = require("mailgun-js")({ apiKey: api_key, domain: domain });

  const [selectionFields, setSelectionFields] = useState(JSON.parse(localStorage.getItem("selection")) || selection);
  const [financeFields, setFinanceFields] = useState(JSON.parse(localStorage.getItem("finance")) || finance);
  const [partExchangefields, setPartExchangeFields] = useState(JSON.parse(localStorage.getItem("partExchange")) || partExchange);
  const [detailsfields, setDetailsFields] = useState(JSON.parse(localStorage.getItem("details")) || details);
  const [summaryFields, setSummaryFields] = useState(JSON.parse(localStorage.getItem("summary")) || summary);
  const [mandatoryFields, setMandatoryFields] = useState({ contactMethods: false, contactTimes: false, contactDays: false, appointmentMethods: false });
  const [showErrors, setShowErrors] = useState(false);

  const preferencesArray = [];
  const modelsArray = [];

  preferences.map((preference) => {
    if (preference.selected) {
      preferencesArray.push(preference.title);
    }
    return undefined;
  });

  models.map((model) => {
    if (model.selected) {
      modelsArray.push(model.title);
    }
    return undefined;
  });

  const navigateNext = async (slug) => {
    const { contactMethods, contactTimes, contactDays, appointmentMethods } = mandatoryFields;
      if (contactMethods && contactTimes && contactDays && appointmentMethods) {
          dispatch({type: "SET_LAYOUT_VISITED", slug: "selection"});
          //history.location.pathname.replace(/\\|\//g, "").toLowerCase()
          history.push(slug);
      }else {
          //set page error  to true
          setShowErrors(true);
      }

  };

  const navigateAmend = (slug) => {
    history.push(slug);
  };


    const updateAppointmentType = (title) => {
      dispatch({ type: "UPDATE_APPOINTMENT", title });
    };
  const updateContactMethods = (title) => {
    dispatch({ type: "UPDATE_SUMMARY_CONTACTMETHOD", title });
  };

  const updateContactTimes = (title) => {
    dispatch({ type: "UPDATE_SUMMARY_CONTACTTIME", title });
  };

  const updateContactDays = (title) => {
    dispatch({ type: "UPDATE_SUMMARY_CONTACTDAY", title });
  };

  // This useEffect will run on first load to check what is in localstorage and populate our context with it
  useEffect(() => {
    const selection = localStorage.getItem("selection");
    const finance = localStorage.getItem("finance");
    const partExchange = localStorage.getItem("partExchange");
    const details = localStorage.getItem("details");
    const summary = localStorage.getItem("summary");

    if (selection) {
      dispatch({ type: "UPDATE_ALL_SELECTIONS", data: JSON.parse(selection) });
      setDetailsFields(selection);
    }

    if (finance) {
      dispatch({ type: "UPDATE_ALL_FINANCES", data: JSON.parse(finance) });
      setDetailsFields(finance);
    }

    if (details) {
      dispatch({ type: "UPDATE_ALL_DETAILS", data: JSON.parse(details) });
      setDetailsFields(details);
    }

    if (partExchange) {
      dispatch({ type: "UPDATE_ALL_PARTEXCHANGE", data: JSON.parse(partExchange) });
      setDetailsFields(partExchange);
    }

    if (summary) {
      dispatch({ type: "UPDATE_ALL_SUMMARY", data: JSON.parse(summary) });
      setSummaryFields(summary);
    }

    // Scroll to top on mobile screens only page load
    if (getSize.getWidth() < 575) {
      try {
        // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
        window.scroll({
          top: 420,
          left: 0,
          behavior: "smooth",
        });
      } catch (error) {
        // just a fallback for older browsers
        window.scrollTo(0, 0);
      }
    } else {
      try {
        // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      } catch (error) {
        // just a fallback for older browsers
        window.scrollTo(0, 0);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This useEffect will set the data into localstorage on selection change
  useEffect(() => {
    const { contactMethods, contactTimes, contactDays, appointmentMethods } = mandatoryFields;

    setSelectionFields(selection);
    localStorage.setItem("selection", JSON.stringify(selection));

    setFinanceFields(finance);
    localStorage.setItem("finance", JSON.stringify(finance));

    setPartExchangeFields(partExchange);
    localStorage.setItem("partExchange", JSON.stringify(partExchange));

    setDetailsFields(details);
    localStorage.setItem("details", JSON.stringify(details));

    setSummaryFields(summary);
    localStorage.setItem("summary", JSON.stringify(summary));

    if (contactMethods && contactTimes && contactDays && appointmentMethods) {
      // Set the layout to completed and then
      setShowErrors(false);
    }
  }, [selection, finance, partExchange, details, summary, mandatoryFields]);

  // This useEffect will check whether any of the fields has been selected and set the button visibility
  useEffect(() => {
    const contactMethodIsEntered = summaryFields.contactMethods.some((element) => {
      return element.selected === true;
    });

      const appointmentMethodIsEntered = summaryFields.appointmentMethods.some((element) => {
          return element.selected === true;
      });

    const contactTimeIsEntered = summaryFields.contactTimes.some((element) => {
      return element.selected === true;
    });

    const contactDayIsEntered = summaryFields.contactDays.some((element) => {
      return element.selected === true;
    });

    setMandatoryFields((prevState) => {
      return { ...prevState, contactMethods: contactMethodIsEntered, contactTimes: contactTimeIsEntered, contactDays: contactDayIsEntered, appointmentMethods: appointmentMethodIsEntered };
    });
  }, [summaryFields]);

  return (
    <div className="card border-0 mb-5 my-md-0 mb-0 mb-md-5">
      <div className="card-body">
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-8 order-2 order-md-1 text-center text-md-left my-4 my-md-0">
            <h1 className="page-title mb-0">Priority Purchase Experience
            </h1>
          </div>
          <div className="col-8 col-md-4 order-1 order-md-2">
            <img className="img-fluid" alt="event logo" src={EventImage} />
          </div>
        </div>

        <div className="row text-center text-md-left justify-content-center justify-content-md-start mt-3">


          <div className="col-12 col-md-6 mt-2 mt-md-0">



            <div className="row">
              <div className="col-12 mb-5">
                <div className="row">
                    {summaryFields.appointmentMethods.map((appointmentMethod) => {
                        return (
                            <div className="col-12 col-md-4 col-lg-3" key={appointmentMethod.title}>
                              <div className="radio mx-auto">
                                <label>
                                  <input
                                      onChange={() => {
                                          updateAppointmentType(appointmentMethod.title);
                                      }}
                                      type="checkbox"
                                      name="appointmentMethods"
                                      value={appointmentMethod.value}
                                      defaultChecked={appointmentMethod.selected}
                                  />
                                    {appointmentMethod.title}
                                  <span className="checkmark" />
                                </label>
                              </div>
                            </div>
                        );
                    })}
                </div>
              </div>
                {showErrors && !mandatoryFields.appointmentMethods && (
                    <div className="col-12">
                      <div className="alert alert-danger mt-1" role="alert">
                        <MdWarning className="mi-18" /> Appointment type is required
                      </div>
                    </div>
                )}
              <div className="col-12 mb-3">
                <h3 className="page-sub-title">How would you like us to contact you back?</h3>
              </div>

              <div className="col-12 mb-3">
                <p className="text-primary">You can choose more than one option</p>
              </div>

              <div className="col-12">
                <div className="row">
                  {summaryFields.contactMethods.map((contactMethod) => {
                    return (
                      <div className="col-12 col-md-4 col-lg-3" key={contactMethod.title}>
                        <div className="radio mx-auto">
                          <label>
                            <input
                              onChange={() => {
                                updateContactMethods(contactMethod.title);
                              }}
                              type="checkbox"
                              name="contactMethods"
                              value={contactMethod.value}
                              defaultChecked={contactMethod.selected}
                            />
                            {contactMethod.title}
                            <span className="checkmark" />
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              {showErrors && !mandatoryFields.contactMethods && (
                <div className="col-12">
                  <div className="alert alert-danger mt-1" role="alert">
                    <MdWarning className="mi-18" /> Contact method is required
                  </div>
                </div>
              )}
            </div>

            <div className="row mt-5">
              <div className="col-12 mb-3">
                <h5>Preferred time(s)</h5>
              </div>

              <div className="col-12">
                <div className="row">
                  {summaryFields.contactTimes.map((contactTime) => {
                    return (
                      <div className="col-12 col-md-4 col-lg-3" key={contactTime.title}>
                        <div className="radio mx-auto">
                          <label>
                            <input
                              onChange={() => {
                                updateContactTimes(contactTime.title);
                              }}
                              type="checkbox"
                              name="contactTimes"
                              value={contactTime.value}
                              defaultChecked={contactTime.selected}
                            />
                            {contactTime.title}
                            <span className="checkmark" />
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              {showErrors && !mandatoryFields.contactTimes && (
                <div className="col-12">
                  <div className="alert alert-danger mt-1" role="alert">
                    <MdWarning className="mi-18" /> Contact time is required
                  </div>
                </div>
              )}
            </div>

            <div className="row mt-5 mb-5">
              <div className="col-12 mb-3">
                <h5>Preferred day(s)</h5>
              </div>

              <div className="col-12">
                <div className="row">
                  {summaryFields.contactDays.map((contactDay) => {
                    return (
                      <div className="col-12 col-md-4 col-lg-3" key={contactDay.title}>
                        <div className="radio mx-auto">
                          <label>
                            <input
                              onChange={() => {
                                updateContactDays(contactDay.title);
                              }}
                              type="checkbox"
                              name="contactDays"
                              value={contactDay.value}
                              defaultChecked={contactDay.selected}
                            />
                            {contactDay.title}
                            <span className="checkmark" />
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              {showErrors && !mandatoryFields.contactDays && (
                <div className="col-12">
                  <div className="alert alert-danger" role="alert">
                    <MdWarning className="mi-18" /> Contact day is required
                  </div>
                </div>
              )}
            </div>



            {showErrors && (
              <div className="row text-center text-md-left mt-5 ">
                <div className="col-12">
                  <div className="alert alert-danger" role="alert">
                    <MdWarning className="mi-18" /> You must complete all mandatory fields above to continue
                  </div>
                </div>
              </div>
            )}

            <div className="row text-center text-md-left justify-content-center justify-content-md-start mt-1">
              <div className="col-12">
                <div className={isLoading ? "btn btn-primary disabled" : "btn btn-primary"} onClick={() => navigateNext("selection")}>
                  Next {isLoading && <Spinner animation="border" size="sm" className="ml-4" />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Priority;
